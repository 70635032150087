.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0px 20px;
  margin: 0 auto;
  max-width: 1440px;
}

.hero-content {
  flex: 1;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slogan {
  font-size: 64px;
  font-family: 'PlayFair', sans-serif;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0px !important;
  color: #064996;
}

.subtitle {
  font-size: 18px;
  text-align: center;
  color: #888888;
  margin-bottom: 70px;
}

.cta-button {
  width: 100%;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  background-color: #FFA500;
  color: #ffffff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.button-group {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.cta-button:hover {
  background-color: #FF8200;
}

.hero-image {
  flex: 1;
}

.hero-image img {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

/* iPad */
@media (min-width: 769px) and (max-width: 1024px) {
  .hero {
    flex-direction: column;
    padding: 20px 10px;
  }

  .hero-content {
    margin-right: 0;
    text-align: center;
  }

  .slogan {
    font-size: 65px;
    margin-top: 0px !important;
  }

  .subtitle {
    margin-bottom: 50px;
  }

  .button-group {
    width: 50%;
  }

  .hero-image img {
    width: 100%;
  }
}



/* Mobile */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    padding: 0px 10px;
  }

  .hero-content {
    margin-right: 0;
    text-align: center;
  }

  .slogan {
    font-size: 36px;
    margin-top: 0px !important;
  }

  .subtitle {
    margin-bottom: 30px;
  }

  .button-group {
    width: 100%;
  }

  .hero-image img {
    width: 100%;
  }
}
