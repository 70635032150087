.navbar {
  position: sticky;
  top: 0;
  background-color: #ffffff;
}

.navbar.mobile {
  position: relative;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
}

.navbar.mobile .navbar-content {
  justify-content: center;
}

.navbar-logo img {
  height: 70px;
  width: auto;
}

.navbar-logo a {
  font-size: 24px;
  font-weight: bold;
  color: #FFA500;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  list-style: none;
}

.menu-item {
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #064996;
  cursor: pointer;
}

.menu-item:hover {
  color: #FFA500;
}
