body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.whatsapp-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 60px;
  height: 60px;
  background-color: #25D366;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99999;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #128C7E;
}

.whatsapp-button svg {
  width: 30px;
  height: 30px;
  fill: #fff;
}

/* Mobile */
@media (max-width: 768px) {
  .whatsapp-button {
    bottom: 20px;
    right: 20px;
  }
}
