/* Existing CSS */
.our-services {
  margin: auto;
  max-width: 990px;
}

.our-service-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.service {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}
 

.service-image img {
  width: 100%;
  height: auto;
}

.service-image {
  flex: 0 0 50%;
}

.service-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px; /* Adjust the left padding */
}


.service-details {
  margin-bottom: 20px;
  text-align: center;
}

.service-block {
  background-color: #f7f7f7;
  padding: 15px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  margin-bottom: 10px; /* Add a bottom margin to service blocks */
  width: 90%; /* Ensures all service blocks take full width on mobile */
}

#hire-us {
  padding-bottom: 30px;
}

.service-block:hover {
  transform: scale(1.05);
}

h2 {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px !important;
  font-family: 'PlayFair', sans-serif;
  color: #064996;
}

.b-20 {
  margin-bottom: 30px !important;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666666;
}

/* Mobile */
@media (max-width: 768px) {
  .our-service-content {
    padding: 0px 15px; /* Add padding to .our-service-content */
    box-sizing: border-box; /* Ensure padding doesn't increase total width */
  }

  .service {
    flex-direction: column;
    width: 100%; /* Set width to 100% for .service */
  }

  .service-image {
    margin-bottom: 0px;
    order: 1;
    width: 100%; /* Set width to 100% for .service-image */
  }

  .service-group {
    box-sizing: border-box;
    padding: 0; /* Remove padding from .service-group */
    order: 2;
    width: 100%; /* Set width to 100% for .service-group */
  }

  #hire-us {
    padding: 0px 20px;
    order: 2;
    width: 100%; /* Set width to 100% for #hire-us */
  }

  #hire-us h2 {
    text-align: center;
  }

  .service-details {
    text-align: left;
    width: 100%; /* Set width to 100% for .service-details */
    display: flex; /* Add flex display */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .service-block {
    padding: 10px;
    max-width: 90%; /* Set width to 100% for .service-block */
  }

  .b-20 {
    margin-top: 50px !important;
  }
}





