.about-me {
  background-color: #ffe1c6;
  padding: 40px;
}

.section-content {
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  max-width: 990px;
}

.about-content {
  flex: 1;
  margin-right: 50px;
}

.contact-info {
  flex: 1;
}

.block-title {
  font-size: 35px;
  font-family: 'PlayFair', sans-serif;
  font-weight: bold;
  color: #064996;
  margin-bottom: 20px;
}

p {
  margin-bottom: 10px;
  color: #03254C !important;
}

.free-estimate {
  color: red !important;
}

/* Mobile */
@media (max-width: 768px) {
  .block-title {
    text-align: center;
    margin-bottom: 30px;
  }

  .section-content {
    flex-direction: column;
  }

  .about-content {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
